/* Add this to your CSS file or component styles */
.custom-tooltip-class {
  max-width:1000px;
  width:1000px;
  margin-left: -500px;
  max-height:480px;
  overflow-y: auto;
  }
  
  @media screen and (max-width: 768px) {
    .custom-tooltip-class {
      /* Adjust styles for smaller screens */
      width: 100%;
      max-width: 100%;
      max-height: 70vh;
    }
  }



 .custom-tooltip-profile{
  
    width: 800px !important;
    max-width: 600px;
    max-height:490px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 300px !important;

 } 


 /* Hide the scroll bar */
/* .introjs-tooltip::-webkit-scrollbar {
  display: none;
} */

/* For Firefox */
/* .introjs-tooltip {
  scrollbar-width: none;
} */

/* width */
.introjs-tooltip::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.introjs-tooltip::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.introjs-tooltip::-webkit-scrollbar-thumb {
  background: #5f5f5f77;
  border-radius: 20px;
}

/* Handle on hover */
/* .introjs-tooltip::-webkit-scrollbar-thumb:hover {
  background: #555;
} */


 .introjs-tooltiptext{
  padding-bottom:5px ;
  padding-top: 5px;
 }



 .introjs-bullets{
  padding-bottom:5px ;
  padding-top: 5px;
 }
 /* .custom-tooltip-profile .introjs-bullets{
  padding-top: 5px;
  padding-bottom: 5px;
 } */


 @media screen and (max-width: 768px) {
  .custom-tooltip-profile {
    /* Adjust styles for smaller screens */
    width: 100%;
    max-width: 100%;
    max-height: 70vh;
  }
}

  .custom-tooltip-content {
    width: 100%; /* Ensure content inside the tooltip takes full width */
  }
  .invite-title {
    font-weight: 600;
    line-height: 26px;
}
