.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}


.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.status-current img {
  border-radius: 50%;
  padding: 1px;
}

/* Profile photos modal */

.customise-profile ul {
  border-bottom: 1px solid #dee2e6;
}

.customise-profile a {
  color: rgb(33, 37, 41);
}

.customise-profile a.active {
  color: var(--txt-secondary);
}

.customise-profile a:hover {
  color: rgb(33, 37, 41);
}

.customise-profile .switch label::after {
  position: absolute;
  content: "Public";
  color: rgb(33, 37, 41);
  font-size: 10px;
  font-weight: bold;
  right: 6px;
  top: 0px;
}

.customise-profile .toggle input+label {
  width: 60px;
  height: 16px;
  margin-bottom: 5px !important;
}

.customise-profile .switch input+label::before {
  left: 0;
  width: 18px;
  z-index: 1;
  height: 16px;
}

.customise-profile .switch input:checked+label::before {
  left: 42px;
}

.customise-profile .switch input:checked+label::after {
  content: "Private";
  right: 20px;
  color: #fff;
  font-weight: bold !important;
}

.cropper-canvas img {
  position: relative;
}

.cropper-crop-box {
  background-color: white;
}

.cropper-drag-box {
  background: fixed;
  width: 400px;
  height: 400px;
}

.visibility-txt {
  position: absolute;
  width: 300px;
  /* top: 0; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  border-radius: 20px;
  background-color: rgb(255, 254, 254);
  padding: 10px;
  z-index: 2;
  left: 22px;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
}

.visibility-txt p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.visibility-instruction:hover .visibility-txt {
  opacity: 1;
  visibility: visible;
}

.profileCropImage {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cropper_button {
  position: absolute;
  top: 85%;
  left: 44%;
  right: 50%;
}

.profileCropImage img {
  max-height: 380px;
  width: auto;
  padding: 20px;
}

/* lement.style {
    background-color: green;
} */

.languages {
  gap: 5px;
}

.interminate,
.expert,
.native {
  margin: 0 2px;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
  font-size: 15px;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
}

.expert {
  background-color: rgb(112, 1, 1);
}

.native {
  background-color: rgb(153, 6, 190);
}

.interminate {
  background-color: rgb(4, 172, 4);
}

.main-availibility {
  position: relative;
  display: inline-block;
}

.availibility-status {
  cursor: pointer;
}

.availibility-status i {
  position: absolute;
  transform: rotate(0deg);
  will-change: transform;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.main-availibility:hover .availibility-status i {
  transform: rotate(180deg) !important;
  position: absolute;
}

ul.availibility-wrapper {
  position: absolute;
  width: max-content;
  top: 40px;
  left: 0px;
  background-color: #fff;
  border: 2px solid #000;
  padding: 10px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
  will-change: transform;
  z-index: 9999;
}

.main-availibility:hover .availibility-wrapper {
  top: 30px;
  visibility: visible;
  opacity: 1;
}



.weekly-day {
  min-width: 100px;
}

.profile__place-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.project__icon {
  width: 60px;
  height: 50px;
  border-radius: 8px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
}

.project__icon img {
  object-fit: cover;
  /* height: 100%; */
  width:100%
}

.service-icon {
  display: flex;
  align-items: center;
}

.service-icon i {
  font-size: 30px;
  margin-bottom: 10px;
}

.project__icon i {
  font-size: 30px;
  margin-bottom: 10px;
  position: relative;
  top: -5px;
}

.service-description {
  white-space: pre-wrap;
  width: 100%;
  word-wrap: break-word;
  /* margin-bottom: 20px !important; */
}
.services-listing__img img {
  width: 100%;
  max-width: 180px;
  min-width: 180px;
  min-height: 120px;
  max-height: 120px;
  border-radius: 10px;
  margin: 0 10px 0 0;
}
.req-brief {
  opacity: 0;
  transition: all 0.3;
}
.network__box:hover .req-brief {
  opacity: 1;
  transition: all 0.3s;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed !important;
}