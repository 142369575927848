.scale-effect .col-md-4::after,
.card-before::after {
    position: absolute;
    width: calc(100% - 30px);
    height: 90%;
    bottom: 0;
    left: 0;
    background: #fff;
    content: "";
    border-radius: 20px;
    transition: transform .15s linear;
    right: 0;
    margin: 0 auto;
}

.scale-effect .col-lg-3,
.scale-effect .network__box__img {
    transition: transform .15s linear;
}


.card-hover {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: calc(100% - 15px);
    width: calc(100% - 30px);
    z-index: 9;
    border-radius: 0px 0px 20px 20px;
    padding-top: 10px;
    transition: transform .15s linear;
    visibility: hidden;
}

.card-hover::after {
    position: absolute;
    width: 100%;
    height: 90%;
    bottom: 0;
    left: 0;
    background: #fff;
    content: "";
    border-radius: 0px 0px 20px 20px;
    transition: transform .15s linear;
    right: 0;
    margin: 0 auto;
    z-index: -1;
}

.scale-effect .col-md-4,
.scale-effect .col-md-3 {
    margin-bottom: 30px;
}

.scale-effect .col-md-4:hover .card-hover {
    visibility: visible;
}


.scale-effect .col-md-4:hover::after,
.scale-effect .col-md-4:hover .network__box__img,
.scale-effect .col-md-4:hover .card-hover::after {
    transform: scale(1.1);
}

.scale-effect .network__box {
    height: 233px;
    margin-bottom: 0;
    box-shadow: 0px 0px 10px var(--box-shadow);
}

.network__box__img,
.network__box__info {
    position: relative;
}

.network__box__img {
    z-index: 2;
}

.network__box__info {
    z-index: 2;
}

.network__box__info .h6, .network__box__info span{
    word-break: break-all;
}

.scale-effect .network__box .network__action {
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 100%;
    visibility: hidden;
    opacity: 1;
    z-index: 3;
}

.scale-effect .network__box:hover .network__action {
    visibility: visible;
}

.scale-effect .network-options {
    width: 27px;
    height: 27px;
    line-height: initial;
    display: flex;
    justify-content: center;
    align-items: center;
}


.scale-effect .network-options ul{
    list-style: none;
}
.scale-effect .network-options ul li{
    height: 29px;
}

.scale-effect .chat-icon img {
    width: 13px;
    margin: 0px auto 0px;
}

.scale-effect .profile-action .ri-more-2-fill {
    font-size: 27px;
}


.network_pop_list {
    display: flex;
    align-items: center;
}

.network_pop_list {
    display: flex;
    align-items: center;
}

.network_pop_list img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.more-connection {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    width: 30px;
}

.more-connection+p {
    font-size: 0.875rem;
    line-height: 16px;
    margin-top: 5px;
}

.more-connection img {
    margin-left: -10px;

}

.text-fade {
    opacity: 0.8;
}

.network_card_btn {
    border: none;
    background-color: #88001b;
    color: #fff !important;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    margin-left: -10px;
    cursor: pointer;
}

.link-secondary,
.link-secondary:hover {
    color: var(--txt-secondary) !important;
}

@media (min-width:991px) and (max-width:1024px) {}