@font-face {
    font-family: 'CabinetGrotesk-Regular';
    src: url('/public/assets/fonts/CabinetGrotesk-Regular.woff2') format('woff2'),
        url('/public/assets/fonts/CabinetGrotesk-Regular.woff') format('woff'),
        url('/public/assets/fonts/CabinetGrotesk-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'CabinetGrotesk-Medium';
    src: url('/public/assets/fonts/CabinetGrotesk-Medium.woff2') format('woff2'),
        url('/public/assets/fonts/CabinetGrotesk-Medium.woff') format('woff'),
        url('/public/assets/fonts/CabinetGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'CabinetGrotesk-Bold';
    src: url('/public/assets/fonts/CabinetGrotesk-Bold.woff2') format('woff2'),
        url('/public/assets/fonts/CabinetGrotesk-Bold.woff') format('woff'),
        url('/public/assets/fonts/CabinetGrotesk-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

:root {
    --default-color: #000;
    --default-font: 'CabinetGrotesk-Regular';
    --default-font-medium: 'CabinetGrotesk-Medium';
    --default-font-bold: 'CabinetGrotesk-Bold';
    --theme-primary-bg: #1F8DCC;
    --theme-text-primary: #1F8DCC;
    --theme-text-secondary: #B7538B;

}


/* GENERAL STYLE */

body {
    color: var(--default-color);
}

.landing-wrapper {
    font-family: var(--default-font);
}

.landing-wrapper img {
    max-width: 100%;
}

.landing-wrapper .container {
    max-width: 1280px;
}

.landing-wrapper button:focus,
input:focus {
    box-shadow: none !important;
}

/* TYPOGRAPHY */

@media (min-width:1140px) {
    .landing-wrapper .heading-h1 {
        font-size: 3.438rem !important;
        line-height: 4rem;
    }
}

.landing-wrapper .heading-h3 {
    font-size: 2.25rem !important;
}

.landing-wrapper p {
    font-size: 1.125rem;
    color: #082220;
}

.landing-wrapper .font-typo-medium {
    font-family: var(--default-font-medium);
}

.landing-wrapper .font-typo-bold {
    font-family: var(--default-font-bold);
}

.landing-wrapper .font-weight-400 {
    font-weight: 400;
}

.landing-wrapper .font-weight-500 {
    font-weight: 500;
}

.landing-wrapper .font-weight-700 {
    font-weight: 700;
}

/* COLORS */

.landing-wrapper .theme-primary-bg {
    background: var(--theme-primary-bg);
}

.landing-wrapper .theme-text-primary {
    color: var(--theme-text-primary);
}

.theme-text-secondary {
    color: var(--theme-text-secondary);
}


/* UTILITY */

:is(section) :is(.section__subheading) {
    font-family: var(--default-font-bold);
}

:is(.section-bright) :is(.btn) {
    background-color: #fff;
    color: #000 !important;
    font-family: var(--default-font-bold);
}

:is(.section-normal) :is(.section__label) {
    color: #fff;
    background: rgb(31, 141, 205);
    background: linear-gradient(90deg, rgba(31, 141, 205, 1) 0%, rgba(109, 57, 125, 1) 53%, rgba(187, 82, 138, 1) 100%);
}

:is(.section-bright) :is(*:not(.section__label)) {
    color: #fff;
}

a {
    text-decoration: none !important;
}

/* START HEADER */

.landing-wrapper .main-header {
    border-bottom: 1px solid #EAECF0;
    height: 78px;
    position: fixed;
    left: 0;
    /* background-color: rgb(209, 209, 209) !important; */
    right: 0;
    margin: 0 auto;
    z-index: 9;
    top: 0;
    transition: all .5s;
    will-change: transform;
    background-color: transparent;
}

.landing-wrapper .headerhide {
    top: -90px;
}

.landing-wrapper .main-header .web-logo, .landing-wrapper .main-header .navbar-brand {
    max-width: 226px;
    width: 100%;
    height:37px;
}

.landing-wrapper .main-header .navbar-nav a {
    padding: 0 !important;
    margin-right: 35px;
    color: #101828;
    line-height: 18px;
    font-size: 18px;
}

.landing-wrapper .main-header .navbar-nav a.active,
.landing-wrapper .main-header .navbar-nav a:hover {
    color: var(--theme-text-secondary);
}

.landing-wrapper .main-header .navbar-nav a:last-child {
    margin-right: 0;
}

.landing-wrapper .header__account a:last-child {
    min-width: 140px;
    color: #fff;
    font-size: 16px;
    height: 45px !important;
    line-height: 45px;
    padding: 0;
    border-radius: 40px;
    margin-right: 0;
}

.landing-wrapper .main-header .navbar-nav {
    border-right: 2px solid #101828;
}

.landing-wrapper .header__account a {
    color: #101828;
    margin-right: 15px;
    font-size: 18px;
    text-decoration: none !important;
}


.landing-wrapper .navbar-toggler-icon {
    background: none;
}

.landing-wrapper .navbar-toggler span {
    width: 30px;
    height: 3px;
    margin: 0 auto;
    display: block;
    background: linear-gradient(90deg, rgba(31, 141, 205, 1) 0%, rgba(109, 57, 125, 1) 53%, rgba(187, 82, 138, 1) 100%);
    border-radius: 50px;
    position: relative;
    transition: all .15s linear;
}

.landing-wrapper .navbar-toggler span::before,
.navbar-toggler span::after {
    background: linear-gradient(90deg, rgba(31, 141, 205, 1) 0%, rgba(109, 57, 125, 1) 53%, rgba(187, 82, 138, 1) 100%);
    width: 100%;
    height: 3px;
    position: absolute;
    border-radius: 50px;
    content: "";
    transition: all .15s linear;
    left: 0;
}

.landing-wrapper .navbar-toggler span::before {
    top: -6px;
}

.landing-wrapper .navbar-toggler span::after {
    top: 6px;
}


.landing-wrapper .toggle-menu span::before {
    transform: rotate(45deg);
    background-color: #fff;
    top: 0;
}

.landing-wrapper .toggle-menu span {
    background: transparent !important;
}

.landing-wrapper .toggle-menu span::after {
    transform: rotate(-45deg);
    top: 0;
    background-color: #fff;
}


/* HERO SECTION */

.body-spacer {
    padding-top: 150px;
}

/* .landing-wrapper svg image{
    margin-top: 550px !important;
} */

.landing-wrapper .hero {
    background: url('/public/assets/images/hero-bg.webp');
    background-size: cover;
}

.landing-wrapper .hero__textslider__items span {
    position: relative;
    overflow: hidden;
}

.landing-wrapper .hero__textslider__items span.theme-text-primary>span.word {
    color: var(--theme-text-primary) !important;
}

/* .landing-wrapper .hero__textslider__items span img {
    position: absolute;
    left: 0;
    bottom: -7px;
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    transition:  clip-path .3s;
    -webkit-transition:  clip-path .3s;
    -moz-transition:  clip-path .3s;
    -ms-transition:  clip-path .3s;
    -o-transition:  clip-path .3s;
} */

/* .swiper-slide-active .hero__textslider__items span img{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

} */

/* .hero__textslider__items__inner span.theme-text-primary::before {
    content: "";
    width: 100%;
    height: 34px;
    background: #FDEFF7;
    position: absolute;
    bottom: -7px;
    right: 0;
    z-index: 1;
} */

/* .swiper-slide-active span.theme-text-primary::before {
    animation: line 1000ms linear forwards;
    -webkit-animation: line 1000ms linear forwards;
    animation-delay: 1500ms;
} */

/* .line-svg{
    width: 0%;
    height: 34px;
    position: absolute !important;
    left: 0;
    bottom: 0px;
    background-color: red;
    overflow: hidden;
} */

/* .line-svg img{
    max-width: inherit;
} */

.swiper-slide-active span.theme-text-primary .line-svg {
    animation: line 1000ms linear forwards;
    -webkit-animation: line 1000ms linear forwards;
    animation-delay: 1500ms;
}

@keyframes line {
    100% {
        /*transform: translateX(100%);*/
        width: 0%;
    }
}

.line-image {
    overflow: hidden;
    display: inline-block;
    clip-path: inset(0 100% 0 0);
    position: absolute;
    left: 0;
    bottom: -24px;
    transition-delay: 1500ms;
}

.swiper-slide-active .hero__textslider__items .line-image {
    animation: animation 1000ms linear forwards;
    -webkit-animation: animation 1000ms linear forwards;
    animation-delay: 1500ms;
}

@keyframes animation {
    100% {
        /*transform: translateX(100%);*/
        clip-path: inset(0);
    }
}

.hero__textslider__items__inner h1 .word {
    z-index: 2;
}

.swiper-slide-active h1 .word {
    animation: slide-in-top .8s ease-in-out;
    animation-delay: calc(60ms * var(--word-index));
    /*-webkit-animation: slide-in-top 2s;
    */
    animation-fill-mode: forwards;
    color: transparent;
    /*-webkit-animation: slide-in-top 1s ease-in;
    */
}

/* Keyframe for the animation */
@keyframes slide-in-top {
    0% {
        transform: translateY(80px);
        /*opacity: 0;
      */
        color: transparent;
        -webkit-transform: translateY(80px);
        -moz-transform: translateY(80px);
        -ms-transform: translateY(80px);
        -o-transform: translateY(80px);
    }

    100% {
        transform: translateY(0);
        /* opacity: 1; */
        color: #000;
    }
}

.landing-wrapper .hero .swiper-pagination {
    position: static;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
}

.landing-wrapper .hero__controls .swiper-pagination span {
    width: 50px;
    height: 5px;
    border-radius: 0px;
    border-radius: 20px;
    background: #D0D5DD;
}

.hero__controls .swiper-pagination-bullet-active {
    background: #BA548C !important;
}


.landing-wrapper .hero p {
    color: #575060;
    font-size: 1.25rem;
}

.swiper-slide-active h1 {
    /* animation: fadeInUp 1s both; */
    position: relative;
}

.swiper-slide-active h1 .theme-text-primary .word {
    color: var(--theme-text-primary) !important;
}

.swiper-slide-active p {
    animation: fadeInUp 1s both;
    animation-delay: .5s;
    position: relative;
}

.swiper-slide-active .hero__textslider__btn {
    animation: fadeInUp 1s both;
    animation-delay: 1s;
    position: relative;
}


.swiper-fade .swiper-slide .swiper-slide {
    pointer-events: all;
}

.hero__controls .btn-outline,
.mob-option .btn-outline {
    color: #B7538B !important;
    border: 1px solid #B7538B !important;
}

.hero__controls .btn-outline:hover,
.mob-option .btn-outline:hover {
    background: linear-gradient(90deg, rgba(31, 141, 205, 1) 0%, rgba(109, 57, 125, 1) 53%, rgba(187, 82, 138, 1) 100%);
    border: none !important;
    color: #fff !important;
}


/* ABOUT SECTION */
.section__label {
    color: #BA548C;
    background-color: #fff;
    display: inline-block;
    line-height: 16px;
    padding: 6px 12px;
    border-radius: 20px;
    margin-bottom: 15px;
    font-weight: 600;
}



/* FEATURE SECTION */

.feaures>div:nth-child(even) {
    flex-direction: row-reverse;
    padding-top: 60px;
    padding-bottom: 60px;
}

.feaures>div:nth-child(even)>div:last-child {
    padding-right: 1.5rem !important;
}

.landing-wrapper .feaures>div:nth-child(odd)>div:last-child {
    padding-left: 1.5rem !important;
}

:is(section) :is(ul) {
    padding: 0;
    margin: 0;
}

.landing-wrapper ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 1.25rem;
    color: #666666;
    list-style: none;
    line-height: 1.2;
}

.landing-wrapper .section__content ul li:before {
    content: url('/public/assets/images/list-icon.svg');
    position: absolute;
    left: 0;
}

.feaures svg image {
    --size: 70%;
    --center: calc((100% - var(--size)) / 2);
    width: var(--size);
    height: var(--size);
    transform: translate(var(--center), var(--center));
}


/* START COUNTER */

.landing-wrapper .counter p {
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 8px;
}

.landing-wrapper .call-action__inner {
    background-color: #E5F6FF;
    padding-left: 60px;
    padding-right: 60px;

}


/* START FOOTER */

.landing-wrapper .footer__seprator {
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 1px;
}

.footer-items {
    flex: 0 0 auto;
}

/*  INDUSTRIES */

.industries__slider span {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    font-size: 1.25rem;
    display: inline-block;
    padding: 10px 30px;
    transition: all .3s linear;
}


.industries__slider span:hover {
    background-color: #B7538B;
}

.industries .swiper-wrapper {
    transition-timing-function: linear;
}

/* .industries__slider {
	width: 200px;
} */

.industries .swiper-slide {
    width: auto !important;
}

/* SEARCH PAGE */

.search-wrap p {
    font-size: 18px;
}

/* .search-wrap form{
    display: flex;
    justify-content: center;
    gap: 15px;
} */

.search-wrap .btn {
    background-color: var(--theme-text-secondary);
    color: #fff !important;
    height: 50px !important;
    border-radius: 50px;
    min-width: 110px;
}

.search-wrap input {
    height: 50px !important;
    border-radius: 50px !important;
    border: none;
    width: 100%;
}

/* BLOG PAGE */

.recent-blog__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.recent-blog__lg img {
    width: 100%;
}

.recent-blog__lg img {
    height: 270px;
    object-fit: cover;
}

.recent-blog__thumb {
    height: 250px;
    object-fit: cover;
}

.recent-blog__grid>div:first-child {
    grid-row-start: 1;
    grid-row-end: 3;
}

.recent-blog__grid>div:first-child~div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 20px; */
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #EAECF0;
}

.recent-blog__lg {
    border: 1px solid #EAECF0;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    background-color: #F9FAFB;
}

.recent-blog__lg>img {
    border-radius: 20px 20px 0px 0px;
    -webkit-border-radius: 20px 20px 0px 0px;
    -moz-border-radius: 20px 20px 0px 0px;
    -ms-border-radius: 20px 20px 0px 0px;
    -o-border-radius: 20px 20px 0px 0px;
}

.blog-category {
    background: #F0F9FF;
    color: #0086C9;
    display: inline-block;
    padding: 6px 15px;
    min-width: 98px;
    text-align: center;
    border-radius: 50px;
    margin-bottom: 12px;
}

.recent-blog__content {
    padding: 20px 20px 20px 20px;
    background: #F9FAFB;
}

.recent-blog__descp p,
.all-blog-posts__descp p,
.blog-link {
    font-size: 1rem;
}

.all-blog-posts__descp {
    padding-bottom: 20px;
    min-height: 90px;
}

.recent-blog__content h2,
.all-blog-posts__content h2 {
    color: #101828 !important;
}


/* ALL BLOG POSTS SECTION CSS START */

.all-blog-posts {
    background-color: #F9FAFB;
}

.all-blog-posts__grid {
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    height: 100%;
}

.all-blog-posts__img {
    height: 250px;
}

.all-blog-posts__img img {
    border-radius: 20px 20px 0px 0px;
    -webkit-border-radius: 20px 20px 0px 0px;
    -moz-border-radius: 20px 20px 0px 0px;
    -ms-border-radius: 20px 20px 0px 0px;
    -o-border-radius: 20px 20px 0px 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.all-blog-posts__content {
    padding: 20px 20px 20px 20px;
}

.blog-link {
    color: #1D2939;
    display: flex;
    align-items: center;
    gap: 0px 5px;
    text-decoration: none !important;
}

.blog-link:hover {
    color: #B7538B;
}

.blog-link i {
    font-size: 24px;
}

.custom-gap {
    gap: 30px 0px;
}

.blog-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog-pagination button {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    gap: 0px 5px;
}

.blog-pagination button:hover {
    color: #B7538B;
}

.blog-pagination .page-numbers {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blog-pagination .page-numbers button {
    width: 40px;
    height: 40px;
    color: #667085 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-pagination .page-numbers button:hover,
.blog-pagination .page-numbers button.active {
    width: 40px;
    height: 40px;
    color: #B7538B !important;
    /* background-color: #FDF2FA !important; */
    background-color: #ffe0f6 !important;
}


/* ALL BLOG POSTS SECTION CSS END */

/* BLOG DETAIL PAGE START */

.category-blk {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.bg-pink {
    background-color: #FFE4E8;
    color: #B7538B;
}

.blog-details p,
.author-details span i {
    font-size: 18px;
    color: #344054;
}

.author-details {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.author-details span {
    display: flex;
    gap: 5px;
    align-items: center;
}

.bg-light-grey {
    background-color: #F9FAFB;
}

.blog-desc ul {
    padding-left: 20px;
}

.blog-desc ul li {
    padding-left: 0px;
    list-style: decimal !important;
    font-size: 18px;
}

.blog-desc img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    margin: 30px 0px;
}

.blog-desc h4 {
    font-weight: 700;
}


.f-18 p {
    font-size: 18px;
}

.blog-hero {
    background: url('/public/assets/images/blog-hero.png');
    background-repeat: no-repeat;
}

.blog-conclusion {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.body-margin {
    margin-top:28px;
}

.blog-spacer-top {
    padding-top: 70px !important;
}

.blog-main-hero {
    position: absolute;
    top: 0;
    width: 100%;
    background-position: right;
    z-index: -1;
    height: 800px;
}

.text-white p {
    color: #fff !important;
}

.blog-post-img img {
    width: 100%;
    object-fit: cover;
}


/* BLOG DETAIL PAGE CSS END */

/* ABOUT PAGE CSS START */
.about-detail .image img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin-bottom: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.about-other-details img {
    height: 480px;
    object-fit: cover;
}

/* ABOUT PAGE CSS END */

.icon-btn i {
    color: #101828 !important;
}

a.btn.mt-3.icon-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

/* SEARCH BLOG CSS START */

.rs-picker-toggle-wrapper,
.rs-stack-item {
    width: 100%;
    overflow: visible !important;
}

.rs-stack-item svg path {
    fill: #667085;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
    border-radius: 38px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 50% !important;
    left: 12px;
    transform: translateY(-50%);
    width: 18px;
}

.rs-btn-close {
    top: 50% !important;
    right: 30px !important;
    transform: translateY(-50%);
}

.rs-picker-toggle-textbox {
    padding: 0px 40px !important;
    color: var(--rs-text-primary) !important;
}

.rs-picker-toggle-value {
    color: var(--rs-text-primary) !important;
}

.rs-picker-daterange-header {
    display: none;
}

.rs-picker-toggle {
    position: relative;
}

.rs-picker-toggle-caret {
    display: none !important;
}

.rs-picker-toggle::before {
    content: url("/public/assets/images/calendar.svg");
    left: 16px;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: 1;
}

.rs-picker-toggle::after {
    content: url("/public/assets/images/arrow-bottom.svg");
    right: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.rs-calendar-body {
    font-family: var(--default-font);
}

.rs-picker-menu .rs-calendar {
    min-width: 300px !important;
}

.rs-calendar-table-row.rs-calendar-table-header-row div:first-child span,
.rs-calendar-table-row.rs-calendar-table-header-row div:last-child span,
.rs-calendar-table-row .rs-calendar-table-cell:first-child span,
.rs-calendar-table-row .rs-calendar-table-cell:last-child span {
    color: red !important;
}

.rs-calendar-table-row .rs-calendar-table-cell.rs-calendar-table-cell-un-same-month span {
    color: transparent !important;
}

.rs-calendar-table-row .rs-calendar-table-cell.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell.rs-calendar-table-cell-un-same-month:hover .rs-calendar-table-cell-content {
    background-color: transparent !important;
}

.rs-picker-toolbar .rs-picker-toolbar-ranges {
    width: 100% !important;
    display: flex;
    flex-wrap: nowrap !important;
}

.rs-picker-toolbar {
    flex-wrap: wrap;
    max-width: 100% !important;
    gap: 10px;
}

.rs-picker-toolbar .rs-stack-item:last-child button {
    width: 100%;
}

.rs-picker-daterange-menu .rs-calendar-header {
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 10px;
}

.rs-calendar-body {
    margin-top: 5px;
}

.rs-calendar-table-row .rs-calendar-table-cell.rs-calendar-table-cell-selected span {
    color: #fff !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: var(--theme-primary-bg) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px var(--theme-primary-bg) !important;
    -webkit-box-shadow: inset 0 0 0 1px var(--theme-primary-bg) !important;
    box-shadow: inset 0 0 0 1px var(--theme-primary-bg) !important;
    box-shadow: inset 0 0 0 1px var(--theme-primary-bg) !important;
}

.gap-y {
    gap: 15px 0px;
}

.error-message {
    margin-top: 8px;
    padding-left: 0.75rem;
}

.PhoneInputCountryIconImg {
    display: block;
    width: 35px;
    height: 100%;
}

.PhoneInputCountryIconImg .PhoneInputInternationalIconGlobe {
    display: none;
}

.site-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 0px;
}

.form__header {
    padding-bottom: 20px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
    border-color: #fff !important;
}

#about section .row.align-items-center {
    align-items: inherit !important;
}

#about section img {
    object-fit: cover;
    height: 100%;
}

.login-wrapper .country .signup-input,
.login-wrapper .country .signup-input:focus {
    border: 1px solid #D5DAE1 !important;
    border-radius: 8px;
    padding: 0.375rem 0.75rem;
    height: 50px;
}

/* MEDIA QUERY START */

@media (max-width:1200px) {

    .landing-wrapper .main-header .navbar-nav a{
        font-size: 1rem;
        margin-right: 12px;
    }

}

@media (max-width:991px) {

    /* TYPOGRAPHT */

    /* .landing-wrapper   .heading-h1 {
        font-size: 3.125rem !important;
    } */


    .landing-wrapper #navbarNavAltMarkup {
        position: fixed;
        left: 0;
        top: 0px;
        width: 80%;
        display: flex !important;
        background: #fff;
        height: 100vh !important;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        padding: 30px 30px 60px 30px;
        transition: transform 1s;
        transform: translateX(-100%);
        will-change: transform;
        padding-top: 34px;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
    }

    .landing-wrapper .toggle-menubar {
        transform: translateX(0) !important;
    }

    .landing-wrapper .main-header .navbar-nav a {
        padding: 0 !important;
        margin-right: 0px;
        font-weight: bold;
        font-size: 1.25rem;
        margin-bottom: 20px;
    }

    .landing-wrapper .main-header .navbar-nav {
        border: none;
        width: 100%;
    }

    .landing-wrapper .navbar-nav.pe-4 {
        padding-right: 0px !important;
    }

    .mob-option {
        position: absolute;
        width: 80% !important;
        margin: 0 auto;
        bottom: 40px;
    }

    .landing-wrapper .heading-h2 {
        font-size: 2.5rem !important;
    }

}

@media (min-width:768px) and (max-width:991px) {

    /* HOMEPAGE FEATURE */

    .feaures>.row:nth-child(odd) .col-md-6:first-child {
        position: sticky;
        top: 40px;
    }

    .feaures>.row:nth-child(even) .col-md-6:first-child {
        position: sticky;
        top: 40px;
    }
}

@media (max-width:767px) {

    /* TYPOGRAPHY */

    .landing-wrapper .heading-h1 {
        font-size: 2.25rem !important;
    }

    .landing-wrapper .heading-h2 {
        font-size: 1.875rem !important;
    }

    :is(section) :is(.section__subheading) {
        font-size: 1.75rem !important;
    }

    .landing-wrapper p {
        font-size: 1rem;
    }

    /* HEADER */

    /* .landing-wrapper .main-header .web-logo {
        max-width: 200px;
        width: 100%;
    } */

    


    /* HERO SECTION AND FEATURES  */

    .landing-wrapper .hero p,
    .listing-ui>li {
        font-size: 1rem;
    }

    .landing-wrapper .call-action__inner {
        padding-left: 20px;
        padding-right: 20px;
    }

    .call-action__inner img {
        max-width: 80px;
        margin-bottom: 20px !important;
    }


    /* BLOG PAGE CSS START */
    .recent-blog__grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .about-detail .image img,
    .about-other-details img,
    .blog-desc img {
        height: auto;
        margin-bottom: 20px;
    }

    .landing-wrapper .headerhide {
        top: 0px !important;
    }

    .body-margin {
        margin-top: 30px;
    }

    .landing-wrapper .hero__textslider__items span .line-image {
        bottom: -15px;
    }

    .body-spacer {
        padding-top: 120px;
    }

    header {
        background-color: #ffff !important;
    }


}

@media (max-width:575px) {
    .recent-blog__grid>div:first-child~div {
        grid-template-columns: repeat(1, 1fr);
    }

    .all-blog-posts .row [class*="col-"]:not(:last-child) {
        margin-bottom: 30px;
    }

    .all-blog-posts__descp {
        min-height: auto;
    }

    .recent-blog__thumb {
        height: 250px;
        object-fit: cover;
    }
}

.btn.btn-primary:hover {
    animation: anime 1s linear infinite !important;
    opacity: 1;
    background-size: 600%;
    -webkit-animation: anime 1s linear infinite !important;
}

.swiper-wrapper>div {
    z-index: 1;
}

.swiper-slide-active {
    z-index: 2 !important;
}

/* .btn:hover{
    opacity: 1 !important;
} */

@keyframes anime {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}