.dropzone-container {
  border-style: dotted;
  padding: 15px;
}

.drop-img{
    width: 198px;
    height:175px;
    border-radius: 20px;
    padding: 5px;
}
.delete_project_image,
.project-profile .project-cross{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 50px;
  font-size: 16px;
  width: 20px;
  height: 20px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.project-profile .project-cross{
  right: -5px;
  top: -5px;
}

.project-profile,
.project-profile img{
  border-radius: 20px;
}





